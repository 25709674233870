// @flow
import React from "react";
import type { Node } from "react";
import tw from "tailwind-styled-components";

type Props = {
  darkMode?: boolean,
  size?: string
}

export const Logo = (props: Props): Node => {
  return (
    <Container>
      <Name darkMode={props.darkMode} size={props.size}>
        penelope
      </Name>
      <Slug size={props.size}>docs</Slug>
    </Container>
  )
}

const Container = tw.div`
  flex 
`

const Name = tw.div`
  font-bold
  text-xl 
  tracking-widest
  uppercase
  
  ${p => p.darkMode && (`
    text-white
  `)}
  
  ${p => p.size === "large" && (`
    text-4xl 
  `)}
`

const Slug = tw.div`
  bg-pink-500
  flex
  font-medium
  inline-block 
  items-center 
  ml-2 
  px-3
  rounded-md
  text-sm 
  text-white
  uppercase
  
  ${p => p.size === "large" && (`
    text-xl
    ml-4
  `)}
`
