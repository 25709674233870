// @flow
import React from "react";
import type { Node } from "react";
import { Accordion as GrommerAccordion, AccordionPanel as GrommerAccordionPanel } from "grommet";

type AccordionProps = {
  a11yTitle?: string,
  activeIndex?: number | Array<number>,
  alignSelf?: "start" | "center" | "end" | "stretch",
  animate?: boolean,
  multiple?: boolean,
  onActive?: () => void,
  children?: any
}

export const Accordion = (props: AccordionProps): Node => {
  return (
    <GrommerAccordion {...props} />
  )
}

type AccordionPanelProps = {
  label: string,
  children?: any
}

export const AccordionPanel = (props: AccordionPanelProps): Node => {
  return (
    <GrommerAccordionPanel {...props} />
  )
}
