import React, { Fragment, useEffect, useState } from "react";
import type Node  from "react";
import { useMount, useScroll, useWindowSize } from "react-use/lib";
import { Github } from 'grommet-icons';
import tw from "tailwind-styled-components";
import { Anchor } from "./Anchor";
import { slugger } from "../utils";

type Props = {
  page: Object,
  location: Object,
  contentRef: any,
  show?: boolean
}

export const Aside = (props: Props): Node => {
  const {y} = useScroll(props.contentRef)
  const {width, height} = useWindowSize()
  const [offsets, setOffsets] = useState([])
  const [imagesToLoad, setImagesToLoad] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const imagesReady = imagesToLoad === imagesLoaded

  useEffect(() => {
    const headings = props.contentRef.current && props.contentRef.current.querySelectorAll('h1, h2')
    headings && setOffsets(
      Array.from(headings)
        .map(heading => {
          const anchor = heading.querySelector('a')
          if (!anchor) { return null }
          return {
            id: heading.id,
            offset: heading.offsetTop + anchor.offsetTop
          };
        })
        .filter(Boolean)
    )
  }, [width, height, props.contentRef, imagesReady])

  useMount(() => {
    if (props.location.hash) {
      // turn numbers at the beginning of the hash to unicode
      // see https://stackoverflow.com/a/20306237/8190832
      const hash = props.location.hash.toLowerCase().replace(
        /^#(\d)/,
        '#\\3$1 '
      )
      try {
        const hashElement = props.contentRef.current.querySelector(hash)
        if (hashElement) {
          hashElement.scrollIntoView();
        }
      } catch (error) {
        // let errors pass
      }
    }

    let toLoad = 0
    const images = props.contentRef.current && props.contentRef.current.querySelectorAll('img')
    images && images.forEach(image => {
      if (!image.complete) {
        image.addEventListener('load', handleImageLoad)
        toLoad++
      }
    })

    setImagesToLoad(toLoad)
  })

  const handleImageLoad = () => {
    setImagesLoaded(
      prevImagesLoaded => prevImagesLoaded + 1
    );
  }

  let activeAsideItem = null
  const windowOffset = height / 2
  const scrollTop = y + windowOffset

  for (let i = offsets.length - 1; i >= 0; i--) {
    const {id, offset} = offsets[i]
    if (scrollTop >= offset) {
      activeAsideItem = id
      break
    }
  }

  return (
    <>
    {
      props.show && (
        <Container>
          <PageTitle>
            {props.page.frontmatter.title}
          </PageTitle>
          {
            props.page.headings.map((item, index) => {
              const slug = slugger([item.value])
              const isActive = activeAsideItem === slug
              return (
                <AnchorLink
                  key={index}
                  to={`${props.location.pathname}#${slug}`}
                  active={isActive}
                  depth={item.depth}
                >
                  {item.value}
                </AnchorLink>
              )
            })
          }
          <UpdateLink
            label="Edit on GitHub"
            a11yTitle="Edit this documentation entry on GitHub"
            href={`https://github.com/smartworkinc/penelope-internal-docs/tree/main/static/content/${props.location.pathname.slice(0, -1)}.md`}
          >
        <span className="inline-block mr-2">
          <Github color="plain" />
        </span>
            Update on GitHub
          </UpdateLink>
        </Container>
      )
    }
    </>
  )
}

Aside.defaultProps = {
  show: true
}

const AnchorLink = tw(Anchor)`
  flex 
  bg-white
  py-1.5
  px-2
  text-gray-500
  font-medium
  rounded-md
  bg-gray-50
  ${p => p.depth === 3 ? (`
    text-sm
    ml-2
  `) : (`
    font-bold
    hover:bg-gray-100
  `)}
`

const Container = tw.div`
  h-full 
  relative 
  flex 
  flex-col 
  w-80
  bg-white
  px-4
  bg-gray-50
  rounded-md
`

const PageTitle = tw.div`
  my-6
  mx-2
  font-bold
`

const UpdateLink = tw(Anchor)`
  ml-2
  mt-8
  text-sm
  font-bold
`
