// @flow
import React from "react";
import type { Node } from "react";
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import tw from "tailwind-styled-components"

type Props = {
  toggleAllPanels: () => void,
  isExpanded: boolean
}

export const ExpandButton = (props: Props): Node => {
  const linkText = props.isExpanded ? "Collapse All" : "Expand All"
  const linkIcon = props.isExpanded ? <MinusIcon className="w-4" /> : <PlusIcon className="w-4" />

  return (
    <NavAnchor
      isExpanded={props.isExpanded}
      onClick={props.toggleAllPanels}
    >
      <LabelContainer>
        {linkText}
      </LabelContainer>
      <IconContainer>
        {linkIcon}
      </IconContainer>
    </NavAnchor>
  )
}

const IconContainer = tw.div`
`

const LabelContainer = tw.div`
  font-medium 
  text-sm 
  uppercase
`

const NavAnchor = tw.div`
  select-none
  flex 
  justify-between 
  items-center
  cursor-pointer 
  -mx-2 mb-2
  px-2 py-1 
  bg-white 
  border 
  rounded-md
  duration-200  
  ${(p) => (p.isExpanded ? (
    "text-pink-600 border-pink-200"
  ) : (
    "text-gray-600 hover:border-gray-300"
  ))}
`
