const slugify = require("github-slugger").slug;

export const slugger = (options, join="/", prefix=null) => {
  const string = options.map(option => slugify(option)).join(join)
  return prefix ? prefix.concat(join, string) : string
}

export const tagger = (tags, path) => {
  return tags.map(tag => ({ label: tag, slug: slugger([tag]), path: `${path}${slugger([tag])}` }) )
}
