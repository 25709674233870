// @flow
import React, { createRef, forwardRef } from "react";
import type Node  from "react";
import { Aside } from "./Aside";
import { Header } from "./Header";
import { Footer } from "./Footer";
import tw from "tailwind-styled-components";

type Props = {
  title: string,
  description?: string,
  page: any,
  pages: Array<Object>,
  next?: any,
  previous?: any,
  children?: Node,
  ref?: any,
  showAside?: boolean
}

export const Main = (props: Props): Node => {
  const ref = createRef();

  return (
    <Container>
        <Content>
          <PrimaryContent ref={ref}>
            <Header title={props.title} description={props.description} />
            <Documentation>
              {props.children}
            </Documentation>
            <Footer />
          </PrimaryContent>
          <AsideContent>
            <Aside
              page={props.page}
              location={props.location}
              contentRef={ref}
              show={props.showAside}
            />
          </AsideContent>
        </Content>
    </Container>
  )
}

const PrimaryContent = forwardRef((props, ref) => {
  return (
      <PrimaryContentSection ref={ref} {...props} />
  )
});

const AsideContent = tw.aside`
  hidden 
  lg:block 
  lg:flex-shrink-0 
  lg:order-last
  py-6
  px-4
`

const Container = tw.main`
  flex-1
  relative
  focus:outline-none
`

const Documentation = tw.div`
`

const Content = tw.div`
  flex-1
  flex
`

const PrimaryContentSection = tw.section`
  min-w-0 
  flex-1 
  flex 
  flex-col
  h-screen
  overflow-y-auto
  lg:order-first
  pt-6
  pb-32
  px-4
  md:px-10
`
