import React from 'react';
import type Node from 'react';
import styled from 'styled-components';
import tw from "tailwind-styled-components";
import { Accordion, AccordionPanel, Anchor } from "../../";
import { useOptions } from "../../../state/hooks";
import { ExpandButton } from "./ExpandButton";
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  categories: Array,
  pages: Array,
  menuLinks: Array,
  location: Object,
}

export const Nav = (props: Props): Node => {
  const { options, toggleAllPanels, setActivePanels } = useOptions()
  const categoryLabels = props.categories.map(item => item.frontmatter.title)
  const data = useStaticQuery(graphql`
      query MenuLinks {
          menuLinks: allMarkdownRemark(
              filter: {
                  frontmatter: {
                      templateKey: {eq: "menu-links"}
                  }
              },
              sort: {
                  fields: [frontmatter___order],
                  order: [ASC]
              }
          ) {
              edges {
                  link: node {
                      frontmatter {
                          title
                          path
                          order
                      }
                  }
              }
          }
      }
  `)
  const staticPages = data.menuLinks.edges.map(item => item.link.frontmatter)

  return (
    <Container>
      <StaticLinkContainer>
        {
          staticPages && staticPages.map(item => {
            return (
              <StaticAnchor
                key={item.path}
                to={item.path}
                active={item.path === props.location.pathname}
                className="block mb-2"
              >
                {item.title}
              </StaticAnchor>
            )
          })
        }
      </StaticLinkContainer>
      <ExpandButton
        toggleAllPanels={() => toggleAllPanels(props.categories)}
        isExpanded={options.isExpanded}
      />
      <AccordionContainer
        multiple={true}
        animate={false}
        activeIndex={options.activePanels}
        onActive={(event) => setActivePanels(event)}
      >
        {
          categoryLabels.filter(item => item !== "Overview").map((category, catIndex) => {
            const items = props.pages.filter(item => item.page.frontmatter.category === category)
            return (
              <AccordionItem
                key={`${category}-${catIndex}`}
                label={category}
              >
                {
                  items.map((item, index) => {
                    return (
                      <AccordionLink
                        key={`${item.page.fields.slug}-${index}`}
                        to={item.page.fields.slug}
                        active={item.page.fields.slug === props.location.pathname}
                      >
                        {item.page.frontmatter.title}
                      </AccordionLink>
                    )
                  })
                }
              </AccordionItem>
            )
          })
        }
      </AccordionContainer>
    </Container>
  )
}

const Container = tw.div`
  h-screen
  overflow-y-auto
  pt-8
  px-6
`

const AccordionContainer = styled(Accordion)`
  div {
    border: 0;
    margin: 0;
    padding: 0;
  }

  svg {
    fill: gray;
    stroke: gray;
  }
`

const AccordionItem = styled(AccordionPanel)`
  h4 {
    font-size: 0.92rem !important;
    font-weight: 800;
    text-transform: uppercase;
    transition: color .2s;
    color: black;
  }

  > div {
    margin: .5rem 0;
  }
`

const AccordionLink = tw(Anchor)`
  font-medium
  my-1
  ml-2
  text-base
`

const StaticAnchor = tw(Anchor)`
  border
  hover:bg-gray-100
  -mx-1
  py-0.5
  px-1
  rounded-md
  ${p => p.active && "bg-gray-100 font-bold"}
`

const StaticLinkContainer = tw.div`
  font-medium
  mb-4
  text-base
`
