import styled from "styled-components";

export const Markdown = styled('div')(props => ({
  h2: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '0.6rem',
    color: props.theme.global.colors.black,
  },
  h3: {
    fontSize: '1.1rem',
    fontWeight: '800',
    marginBottom: '0.3rem'
  },
  h4: {
    fontSize: '1.25em',
    fontWeight: '400',
  },
  h5: {
    fontSize: '1.055em',
    fontWeight: '400',
  },
  h6: {
    fontSize: '1.055em',
    fontWeight: '600',
    marginBottom: '0.5em',
  },
  em: {
    background: props.theme.global.colors["light-2"],
    fontStyle: 'normal',
    fontWeight: '500',
    color: props.theme.global.colors["accent-2"],
    paddingLeft: '6px',
    paddingRight: '6px',
    letterSpacing: '0.4px',
    borderRadius: '4px'
  },
  [['ul', 'ol']]: {
    marginTop: '2rem',
    marginBottom: '2rem',
    marginLeft: '1.45em'
  },
  li: {
    marginBottom: '.725em'
  },
  [['p', 'li']]: {
    color: "black",
    fontSize: '1.15rem',
    lineHeight: '1.7rem'
  },
  // style all anchors with an href and no prior classesh2
  // this helps avoid anchors with names and styled buttons
  'a[href]:not([class])': {
    color: props.theme.global.colors.brand,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    },
    code: {
      color: 'inherit'
    }
  },
  [['h1', 'h2', 'h3', 'h4', 'h5', 'h6']]: {
    fontFamily: '"Muli", sans-serif',
    fontStyle: 'initial',
    marginTop: '0',
    textTransform: "initial",
    ':not(:hover) a svg': {
      visibility: 'hidden'
    },
    code: {
      whiteSpace: 'normal'
    },
    'a.anchor': {
      ':hover': {
        opacity: '.9'
      },
      svg: {
        fill: props.theme.global.colors.brand
      },
      '&.before': {
        top: 'auto'
      }
    }
  },
  '*:not(style) +': {
    [['h2', 'h3', 'h4']]: {
      marginTop: '32px'
    }
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    margin: '0 auto'
  },
  '.mermaid svg': {
    maxWidth: '100%'
  },
  blockquote: {
    margin: '0px',
    borderLeftWidth: '3px',
    borderLeftColor: props.theme.global.colors.brand,
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '20px',
    paddingRight: '12px',
    marginBottom: '32px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    background: props.theme.global.colors['light-1'],
    p: {
      color: props.theme.global.colors['dark-1'],
      fontSize: '0.9em',
      lineHeight: '1.4em',
      fontStyle: 'normal'
    }
  },
  table: {
    borderTopLeftRadius: '0.6rem',
    borderTopRightRadius: '0.6rem',
    overflow: 'hidden',
    margin: '2rem 0',
    minWidth: '100%',
    thead: {
      color: props.theme.global.colors.white,
      background: props.theme.global.colors.brand,
    },
    [['td', 'th']]: {
      padding: '.4rem .6rem',
      fontSize: '1.1rem',
    },
    th: {
      padding: '.6rem .6rem',
      textAlign: 'left',
      fontSize: '0.9rem',
    },
    td: {
      width: 'fit-content',
      borderBottom: '1px solid slategray'
    },
  },
  [[
    "div.gatsby-highlight[data-language='alert-']",
    "div.gatsby-highlight[data-language='alert-warn']",
    "div.gatsby-highlight[data-language='alert-danger']",
    "div.gatsby-highlight[data-language='alert-success']"
  ]]: {
    pre: {
      fontSize: '1.1rem',
      fontWeight: '800',
      lineHeight: '1.6rem',
      borderRadius: '0.6rem',
      padding: '0.6rem 0.8rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      whiteSpace: 'normal',
      margin: '1.4rem 0 1rem 0',
      color: props.theme.global.colors["dark-5"],
      background: props.theme.global.colors["light-0"],
      borderColor: props.theme.global.colors["light-3"],
      code: {
        fontFamily: '"Muli", sans-serif',
        fontSize: '1.1rem',
        lineHeight: '1.2rem',
      }
    }
  },
  "div.gatsby-highlight[data-language='alert-warn']": {
    pre: {
      borderWidth: '0.1rem',
      borderStyle: "solid",
      borderColor: props.theme.global.colors["status-warning"],
      backgroundColor: props.theme.global.colors["status-warning"] + '10',
    }
  },
  "div.gatsby-highlight[data-language='alert-danger']": {
    pre: {
      borderWidth: '0.1rem',
      borderStyle: "solid",
      borderColor: props.theme.global.colors["status-error"],
      backgroundColor: props.theme.global.colors["status-error"] + '10',
    }
  },
  "div.gatsby-highlight[data-language='alert-success']": {
    pre: {
      borderWidth: '0.1rem',
      borderStyle: "solid",
      borderColor: props.theme.global.colors["status-ok"],
      backgroundColor: props.theme.global.colors["status-ok"] + '10',
    }
  },
  "div.gatsby-highlight[data-language='ssh']": {
    pre: {
      background: props.theme.global.colors["dark-4"],
      color: 'white',
      fontWeight: '500',
      padding: '12px',
      paddingRight: '12px',
      whiteSpace: 'normal',
      maxWidth: '600px'
    }
  },
}));
