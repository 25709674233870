import React from "react";
import type Node from "react";
import tw from "tailwind-styled-components";
import { Logo } from "./Logo"
import { Nav } from "./Nav"

type Props = {
  categories: Array,
  pages: Array,
  menuLinks: Array,
  location: Object,
  open: boolean,
  setOpen: () => void
}

export const Sidebar = (props: Props): Node => {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <NavContainer>
          <Nav
            scope={"mobile"}
            categories={props.categories}
            pages={props.pages}
            menuLinks={props.menuLinks}
            location={props.location}
          />
        </NavContainer>
      </Content>
    </Container>
  );
}

const Container = tw.div`
  bg-gray-50 
  hidden 
  flex 
  flex-col
  md:flex 
  md:flex-shrink-0
  w-80
`

const Content = tw.div`
  flex 
  flex-col 
  flex-grow
  pt-5 
  pb-4 
`

const LogoContainer = tw.div`
  items-center 
  flex 
  flex-shrink-0
  pl-6
`

const NavContainer = tw.div` 
  flex
  flex-1  
  flex-col
  mt-4
`
