// @flow
import React from "react";
import { Link } from "gatsby";
import tw from "tailwind-styled-components";

export const Anchor = tw(Link)`
  text-black  
  transition 
  duration-200
  hover:text-pink-600
  ${(p) => p.active && "text-pink-600"}
`
