import React from "react";
import type Node from "react";
import tw from "tailwind-styled-components";

type Props = {
  title: string,
  description?: string
}

export const Header = (props: Props): Node => {
  return (
    <Container>
      <Heading>{props.title}</Heading>
      <Description>{props.description}</Description>
    </Container>
  );
}

const Container = tw.div`
  border-b
  border-gray-300
  pb-4
  mb-4
`

const Description = tw.div`
  font-medium
  text-xl
  text-gray-600
  mt-0.5
`

const Heading = tw.h1`
  font-bold
  text-3xl
`
